// Generated by Framer (b12dec4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/mwh_X_s20";

const enabledGestures = {"I21:958;32:613": {hover: true, pressed: true}};

const cycleOrder = ["I21:958;32:613"];

const variantClassNames = {"I21:958;32:613": "framer-v-183f9g9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, label, link, tap, width, ...props}) => { return {...props, DwahwJP3m: label ?? props.DwahwJP3m ?? "Placeholder", NaSOIvB38: tap ?? props.NaSOIvB38, rVVDCRlxY: link ?? props.rVVDCRlxY} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;label?: string;link?: string;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, DwahwJP3m, rVVDCRlxY, NaSOIvB38, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "I21:958;32:613", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap8jnmot = activeVariantCallback(async (...args) => {
if (NaSOIvB38) {
const res = await NaSOIvB38(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-CH0zW", sharedStyle.className, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={rVVDCRlxY}><motion.a {...restProps} className={`${cx("framer-183f9g9", className)} framer-13sbkbl`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"I21:958;32:613"} onTap={onTap8jnmot} ref={ref} style={{backgroundColor: "var(--token-6f2dc3f6-ebea-4632-8769-b8586600d2bf, rgb(64, 93, 229))", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, ...style}} variants={{"I21:958;32:613-hover": {backgroundColor: "var(--token-451ba964-1026-4a58-b30b-079a1f0bb46a, rgb(56, 83, 204))"}, "I21:958;32:613-pressed": {backgroundColor: "var(--token-6952652d-1e42-49f7-a19e-ea77b7bb2fb0, rgb(40, 62, 159))"}}} {...addPropertyOverrides({"I21:958;32:613-hover": {"data-framer-name": undefined}, "I21:958;32:613-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1ptr2en"} data-styles-preset={"mwh_X_s20"} style={{"--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>Placeholder</motion.p></React.Fragment>} className={"framer-58g775"} data-framer-name={"Placeholder"} layoutDependency={layoutDependency} layoutId={"I21:958;32:614"} style={{"--extracted-r6o4lv": "var(--token-d7efcd7f-f73e-4e3c-815e-e6895d3aa516, rgb(255, 255, 255))", "--framer-paragraph-spacing": "0px"}} text={DwahwJP3m} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-CH0zW [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-CH0zW .framer-13sbkbl { display: block; }", ".framer-CH0zW .framer-183f9g9 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: 40px; justify-content: center; overflow: visible; padding: 0px 16px 0px 16px; position: relative; text-decoration: none; width: min-content; }", ".framer-CH0zW .framer-58g775 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-CH0zW .framer-v-183f9g9 .framer-183f9g9 { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-CH0zW .framer-183f9g9 { gap: 0px; } .framer-CH0zW .framer-183f9g9 > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-CH0zW .framer-183f9g9 > :first-child { margin-left: 0px; } .framer-CH0zW .framer-183f9g9 > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 112.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]},"If5TZQv8L":{"layout":["auto","fixed"]},"l85GDsoxL":{"layout":["auto","fixed"]}}}
 * @framerVariables {"DwahwJP3m":"label","rVVDCRlxY":"link","NaSOIvB38":"tap"}
 * @framerImmutableVariables false
 */
const FrameroS1W3BcLh: React.ComponentType<Props> = withCSS(Component, css, "framer-CH0zW") as typeof Component;
export default FrameroS1W3BcLh;

FrameroS1W3BcLh.displayName = "button";

FrameroS1W3BcLh.defaultProps = {height: 40, width: 112.5};

addPropertyControls(FrameroS1W3BcLh, {DwahwJP3m: {defaultValue: "Placeholder", displayTextArea: false, title: "Label", type: ControlType.String}, rVVDCRlxY: {title: "Link", type: ControlType.Link}, NaSOIvB38: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FrameroS1W3BcLh, [...sharedStyle.fonts])